import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { Address } from 'src/app/models/address/address';
import { ApsPermitedScreen } from 'src/app/models/aps-permission/aps-permited-screen';
import { ChassiValidation } from 'src/app/models/chassi-validation/chassi-validation';
import { ClientData } from 'src/app/models/client-data/client-data';
import { SaleChannel } from 'src/app/models/enum/sale-channel';
import { StatusChassi } from 'src/app/models/enum/status-chassi';
import { AddressService } from 'src/app/services/address/address.service';
import { ApsPermissionService } from 'src/app/services/aps-permission/aps-permission.service';
import { ClientDataService } from 'src/app/services/client-data/client-data.service';
import { SystemCountryService } from 'src/app/services/system-country/system-country.service';
import { VehicleInformationService } from 'src/app/services/vehicle-information/vehicle-information.service';
import { validationCpfCnpj } from 'src/assets/validations/cpf_cnpj_validation';
import { ConstantsFordProtect } from "src/app/models/constants/constants-ford-protect";


@Component({
	selector: 'client-data',
	templateUrl: './client-data.component.html',
	styleUrls: ['./client-data.component.scss'],
})
export class ClientDataComponent implements OnInit {

	userform: FormGroup;
	clientData: ClientData;
	address: Address;
	saleChannel: string;
	chassiValidation: ChassiValidation;
	country: string;
	nameMaxLength:number = 100;
	lastNameMaxLength:number = 50;
	addressNumberMaxLength:number = 10;
	maxBirthDate: Date;

	constructor(
		private vehicleInformationService: VehicleInformationService,
		private fb: FormBuilder,
		private router: Router,
		private route: ActivatedRoute,
		private addressService: AddressService,
		private clientDataService: ClientDataService,
		private translateService: TranslateService,
		private messageService: MessageService,
		private apsPermissionService: ApsPermissionService,
		private systemCountryService: SystemCountryService,
	) {
		this.address = new Address();
		this.clientData = new ClientData();
		this.saleChannel = route.snapshot.paramMap.get('saleChannel');
		this.systemCountryService.setSystemCountry();
		const today = new Date();
  		this.maxBirthDate = new Date(
   	 	today.getFullYear() - 18,
    	today.getMonth(),
    	today.getDate());
	}

	ngOnInit() {
		
		this.chassiValidation = this.vehicleInformationService.getAllChassiValidation();
		if (this.chassiValidation == null || this.chassiValidation.validated == StatusChassi.invalid) {
			this.router.navigate(['sales-channel', this.saleChannel], { skipLocationChange: true, replaceUrl: false });
		}

		this.country = this.systemCountryService.getSystemCountry();
		if(this.country === ConstantsFordProtect.BRA){
			this.nameMaxLength = 49;
			this.lastNameMaxLength = 50;
			this.addressNumberMaxLength = 6;
		}

		switch (this.systemCountryService.getSystemCountry()) {
			case "COL":

				(<HTMLInputElement>document.getElementById("document")).minLength = 4;
				(<HTMLInputElement>document.getElementById("document")).maxLength = 10;
				var cpfDocument = new FormControl('', [Validators.required, Validators.minLength(4), Validators.maxLength(10), Validators.pattern('[0-9]*')]);
				var cepFormControl = new FormControl('', [Validators.minLength(0), Validators.maxLength(8), Validators.pattern('[0-9]*')]);
				var stateFormControl = new FormControl('', [Validators.required, Validators.pattern('[A-zÁ-ú ]*'), Validators.minLength(2), Validators.maxLength(30)]);
				this.userform = this.formControlGenerator(7, 12, cepFormControl, cpfDocument, stateFormControl);

				(<HTMLInputElement>document.getElementById("firstname")).maxLength = 100;
				(<HTMLInputElement>document.getElementById("firstname")).minLength = 2;
				(<HTMLInputElement>document.getElementById("phone1")).maxLength = 12;
				(<HTMLInputElement>document.getElementById("phone1")).minLength = 7;
				(<HTMLInputElement>document.getElementById("email1")).maxLength = 50;
				(<HTMLInputElement>document.getElementById("zipCode")).maxLength = 8;
				(<HTMLInputElement>document.getElementById("address")).maxLength = 50;
				(<HTMLInputElement>document.getElementById("number")).maxLength = 10;
				(<HTMLInputElement>document.getElementById("complement")).maxLength = 20;
				(<HTMLInputElement>document.getElementById("district")).maxLength = 50;
				(<HTMLInputElement>document.getElementById("city")).maxLength = 50;
				(<HTMLInputElement>document.getElementById("stateCode")).maxLength = 30;

				break;

			case "CHL":

				(<HTMLInputElement>document.getElementById("document")).minLength = 9;
				(<HTMLInputElement>document.getElementById("document")).maxLength = 9;
				var cpfDocument = new FormControl('', [Validators.required, Validators.minLength(9), Validators.maxLength(9), Validators.pattern('[0-9]*')]);
				var cepFormControl = new FormControl('', [Validators.minLength(0), Validators.maxLength(10), Validators.pattern('[0-9]*')]);
				var stateFormControl = new FormControl('', [Validators.required, Validators.pattern('[A-zÁ-ú ]*'), Validators.minLength(2), Validators.maxLength(30)]);

				this.userform = this.formControlGenerator(11, 11, cepFormControl, cpfDocument, stateFormControl);
				
				(<HTMLInputElement>document.getElementById("firstname")).maxLength = 100;
				(<HTMLInputElement>document.getElementById("firstname")).minLength = 2;
				(<HTMLInputElement>document.getElementById("phone1")).maxLength = 11;
				(<HTMLInputElement>document.getElementById("phone1")).minLength = 11;
				(<HTMLInputElement>document.getElementById("email1")).maxLength = 50;
				(<HTMLInputElement>document.getElementById("zipCode")).maxLength = 10;
				(<HTMLInputElement>document.getElementById("address")).maxLength = 50;
				(<HTMLInputElement>document.getElementById("number")).maxLength = 10;
				(<HTMLInputElement>document.getElementById("complement")).maxLength = 20;
				(<HTMLInputElement>document.getElementById("district")).maxLength = 50;
				(<HTMLInputElement>document.getElementById("city")).maxLength = 50;
				(<HTMLInputElement>document.getElementById("stateCode")).maxLength = 30;

				break;

			default:

				(<HTMLInputElement>document.getElementById("document")).minLength = 11;
				(<HTMLInputElement>document.getElementById("document")).maxLength = 14;
				var cpfCnpjDocument = new FormControl('', [Validators.required, Validators.minLength(11), Validators.maxLength(14), Validators.pattern('[0-9 ]*'), validationCpfCnpj]);
				var cepFormControl = new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(8), Validators.pattern('[0-9]*')]);
				var stateFormControl = new FormControl('', [Validators.required, Validators.pattern('[A-zÁ-ú ]*'), Validators.minLength(2), Validators.maxLength(2)]);

				this.userform = this.formControlGenerator(11, 11, cepFormControl, cpfCnpjDocument, stateFormControl);

				(<HTMLInputElement>document.getElementById("firstname")).maxLength = 49;
				(<HTMLInputElement>document.getElementById("firstname")).minLength = 2;
				(<HTMLInputElement>document.getElementById("lastName")).maxLength = 50;
				(<HTMLInputElement>document.getElementById("phone1")).maxLength = 11;
				(<HTMLInputElement>document.getElementById("phone1")).minLength = 11;
				(<HTMLInputElement>document.getElementById("email1")).maxLength = 50;
				(<HTMLInputElement>document.getElementById("zipCode")).maxLength = 8;
				(<HTMLInputElement>document.getElementById("address")).maxLength = 50;
				(<HTMLInputElement>document.getElementById("number")).maxLength = 6;
				(<HTMLInputElement>document.getElementById("complement")).maxLength = 20;
				(<HTMLInputElement>document.getElementById("district")).maxLength = 50;
				(<HTMLInputElement>document.getElementById("city")).maxLength = 50;
				(<HTMLInputElement>document.getElementById("stateCode")).maxLength = 2;

				break;

		}
		
		this.apsPermissionService.permissionValidation(ApsPermitedScreen.screenCustomerValidation);

		if (this.clientDataService.getClientData() != null) {
			this.clientData = this.clientDataService.getClientData();
		}

		if (this.clientData.countryCode == null) {
			this.clientData.countryCode = 'BRA';
		}

		if (this.clientData.complement == null) {
			this.clientData.complement = "";
		}

	}
	
	buttonComeBack() {
		this.router.navigate(['choose-plan', this.saleChannel], { skipLocationChange: true, replaceUrl: false });
	}
	
	onKeyUpAddress(event: KeyboardEvent): void {
		if ((this.clientData.zip != "") && (this.clientData.zip != null)) {
			if (event.key !== "Control" && this.clientData.zip.length >= 8) {
				const newZip = this.clientData.zip;
				this.searchAddress(newZip.replace("-", ""));
			}
		}
	}

	searchAddress(zipCode: string) {
		if(this.systemCountryService.getSystemCountry() == ConstantsFordProtect.BRA){
		this.addressService.getAddress(zipCode)
			.subscribe((response: Address) => {
				if (response == null || response.zipCode == null || response.zipCode == "" && response.country == 'BRA') {
					this.messageService.add({ severity: 'warn', summary: this.translateService.instant('TRANSLATE.ALERT'), detail: this.translateService.instant('TRANSLATE.ADDRESS.NOT.FOUND.AGENDA') });
				} else {
					this.address = response;
					this.clientData.countryCode = response.country;
					this.clientData.address = response.address;
					this.clientData.district = response.addressDistrict;
					this.clientData.city = response.city;
					this.clientData.stateCode = response.state;
					this.clientData.zip = response.zipCode;
					this.clientData.number = "";
					this.clientData.complement = "";
				}
			}, (err) => {
				this.messageService.add({
					severity: 'error',
					summary: this.translateService.instant('TRANSLATE.ERROR'),
					detail: this.translateService.instant('TRANSLATE.CEP.NOT.FOUND')
				});
			});
		}
	}

	public noWhitespaceValidator(control: FormControl) {
		const isSpace = (control.value || '').match(/^[ \t]+|[ \t]+$/g);
		return isSpace ? { 'whitespace': true } : null;
	}
	
	private formControlGenerator(phoneMinlength: number, phoneMaxlength: number,
		cepValidator: FormControl, cpfDocument: FormControl, stateAddress: FormControl) {

			return this.fb.group({
				'inputDocument': cpfDocument,
				'inputName': new FormControl('', [Validators.required, Validators.maxLength(this.nameMaxLength), Validators.pattern('[A-zÁ-ú ]*')]),
				'inputLastName': new FormControl('', [Validators.required, this.noWhitespaceValidator, Validators.minLength(2), Validators.maxLength(this.lastNameMaxLength), Validators.pattern('[A-zÁ-ú ]*')]),
				'inputSocialName': new FormControl(null, [Validators.maxLength(50), Validators.pattern('[A-zÁ-ú ]*')]),
				'inputPhoneNumber': new FormControl('', [Validators.required, Validators.minLength(phoneMinlength), Validators.maxLength(phoneMaxlength), Validators.pattern('[0-9]*')]),
				'inputEmail': new FormControl('', [Validators.required, Validators.email]),
				'inputZipCode': cepValidator,
				'inputAddress': new FormControl('', [Validators.required, Validators.pattern('[A-zÁ-ú0-9. ()]*')]),
				'inputNumber': new FormControl('', [Validators.required, Validators.maxLength(this.addressNumberMaxLength)]),
				'inputComplement': new FormControl(''),
				'inputDistrict': new FormControl('', [Validators.required, Validators.pattern('[A-zÁ-ú0-9 ()]*')]),
				'inputCity': new FormControl('', [Validators.required, Validators.pattern('[A-zÁ-ú ]*')]),
				'inputState': stateAddress,
				'inputBirthDate': new FormControl(null, [Validators.required, Validators.minLength(10)])
			});

	}

	buttonNext() {

		if (this.country !== "BRA" && this.userform.get('inputDocument').invalid) {
			this.userform.get('inputDocument').markAsPending();
		}
		if (this.country === "BRA" && this.userform.get('inputDocument').invalid) {
			this.userform.get('inputDocument').markAsPending();
		}
		if (this.userform.get('inputName').invalid) {
			this.userform.get('inputName').markAsPending();
		}
		if (this.userform.get('inputLastName').invalid) {
			this.userform.get('inputLastName').markAsPending();
		}
		if (this.userform.get('inputPhoneNumber').invalid) {
			this.userform.get('inputPhoneNumber').markAsPending();
		}
		if (this.userform.get('inputEmail').invalid) {
			this.userform.get('inputEmail').markAsPending();
		}
		if (this.userform.get('inputZipCode').invalid) {
			this.userform.get('inputZipCode').markAsPending();
		}
		if (this.userform.get('inputAddress').invalid) {
			this.userform.get('inputAddress').markAsPending();
		}
		if (this.userform.get('inputNumber').invalid) {
			this.userform.get('inputNumber').markAsPending();
		}
		if (this.userform.get('inputDistrict').invalid) {
			this.userform.get('inputDistrict').markAsPending();
		}
		if (this.userform.get('inputCity').invalid) {
			this.userform.get('inputCity').markAsPending();
		}
		if (this.userform.get('inputState').invalid) {
			this.userform.get('inputState').markAsPending();
		}
		if (this.country == ConstantsFordProtect.BRA && this.userform.get('inputBirthDate').invalid) {
			this.userform.get('inputBirthDate').markAsPending();
		}
		if(this.country != ConstantsFordProtect.BRA){
			this.userform.get('inputBirthDate').setErrors(null);
		}
		if(this.userform.valid){
			
				this.setZipCode();
				this.clientDataService.setClientData(this.clientData);
				if (this.saleChannel == SaleChannel.EXTERNAL) {
					this.router.navigate(['contract-summary-external', this.saleChannel], { skipLocationChange: true, replaceUrl: false });
				} else {
					this.router.navigate(['contract-summary', this.saleChannel], { skipLocationChange: true, replaceUrl: false });
				}				

    }

	}

	setZipCode() {
		if ((this.clientData.zip != "") && (this.clientData.zip != null)) {
			this.clientData.zip = this.clientData.zip.replace("-", "");
		}
	}

	public maskCep = {
		guide: false,
		showMask: true,
		mask: [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]
	};

	searchCustomers(country: string, nationalId: string) {
		this.clientDataService.getCustomers(country, nationalId)
			.subscribe((response: ClientData) => {
				if (response == null || response.nationalId == null || response.nationalId == "") {
					this.messageService.add({
						severity: 'warn',
						summary: this.translateService.instant('TRANSLATE.ALERT'),
						detail: this.translateService.instant('TRANSLATE.CLIENTE.NOT.FOUND.AGENDA')
					});
				} else {
					this.clientData = response;
					this.clientData.phone1 = response.ddd1 + response.phone1;
					this.clientData.cell = false;
					this.clientData.notification = false;
					this.clientData.whatsApp = false;
				}
			}, (err) => {
				this.messageService.add({
					severity: 'error',
					summary: this.translateService.instant('TRANSLATE.ERROR'),
					detail: this.translateService.instant('TRANSLATE.CPF.CNPJ.NOT.FOUND')
				});
			});
	}

	maxLengthLastname(): number {
		let maxLength: number = 50;
		if ('BRA' === this.systemCountryService.getSystemCountry()) {
			maxLength = 30;
		}
		return maxLength;
	}

}
