export enum StatusProduct {

  CONTRACT_ANALYSIS_DEPTO = 5,
  CONTRACT_ANALYSIS_DEPTO_DESCRIPTION = "Analise Depto Contrato",

	FINANCIAL_STATEMENT_DEPTO_ANALYSIS = 10, 
	FINANCIAL_ANALYSIS_DEPTO_DESCRIPTION = "Analise Depto Financeiro",

	AWAITING_AP_SUP_FINANC = 15,
	AWAITING_AP_SUP_FINANC_DESCRIPTION = "Aguardando Aprov Supervisor Financeiro",

	CANCELLED = 20, 
	CANCELED_DESCRIPTION = "Cancelado",

	ACTIVE = 25, 
	ACTIVE_DESCRIPTION = "Ativo",

	INACTIVE = 30, 
	INACTIVE_DESCRIPTION ="Inativo"

}