
export class ClientData {
    
    customerId: number;
    countryCode: string;
    divisionCode: number;
    nationalId: string;
    typeOfPerson: string;
    firstname: string;
    lastname: string;
    address: string;
    number: string;
    complement: string;
    district: string;
    city: string;
    stateCode: string;
    stateName: string;
    zip: string;
    ddd1: string;
    phone1: string;
    ddd2: string;
    phone2: string;
    phone2Extension: string;
    dddMobile: string;
    mobilePhone: string;
    email1: string;
    email2: string;
    createDate: Date;
    updateDate: Date;
    company: string;
    cityRegistration: string;
    stateRegistration: string;
    errorMessage: string;
    whatsApp: Boolean;
    cell: Boolean;
    notification: Boolean;
    customerSocialName: string;
    birthDate: Date;

	constructor() {
		
	}
}
