import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MessageService } from 'primeng';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProductPolicy } from 'src/app/models/product/product-policy';
import { ProductPolicyEditDto } from 'src/app/models/product/product-policy-edit';
import { ProductPolicyRequestDto } from "src/app/models/product/product-policy-request";
import { ProductPolicyResponseDto } from "src/app/models/product/product-policy-response";
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class ProductPolicyService {
  
  private apiUrl = environment.apiUrl;

  constructor(
    private httpClient: HttpClient,
    private messageService: MessageService
  ) {
  }

  getAllProductPoliciesByCountry(countries: Array<string>): Observable<Array<ProductPolicy>> {
    return this.httpClient.post<Array<ProductPolicy>>(`${this.apiUrl}/api/v1/products/policies/countries`, countries, httpOptions)
      .pipe(
        map((response: Array<ProductPolicy>) => {
          return response;
        })
      );
  }
  
  getAllProductPolicies(): Observable<Array<any>> {
    return this.httpClient.get(`${this.apiUrl}/api/v1/products/policies`)
      .pipe(
        map((response: Array<any>) => {
          return response;
        })
      );
  }

  save(requestDto: ProductPolicyRequestDto): Observable<ProductPolicyResponseDto> {
    return this.httpClient.post(`${this.apiUrl}/api/v1/products/policies`, requestDto, httpOptions)
      .pipe(
        map((response: ProductPolicyResponseDto) => {
          return response;
        })
      );
  }

  getById(id: number):  Observable<ProductPolicyEditDto>{
  return this.httpClient.get(`${this.apiUrl}/api/v1/products/policies/id/${id}`)
  .pipe(
    map((response: ProductPolicyEditDto) => {
      return response;
    })
  );
}

updateProductPolicy(productPolicy :ProductPolicyEditDto): Observable<ProductPolicyResponseDto>{
  return this.httpClient.put(`${this.apiUrl}/api/v1/products/policies`, productPolicy, httpOptions)
      .pipe(
        map((response: ProductPolicyResponseDto) => {
          return response;
        })
      );
}

}
