export class NewProduct {
  
  id: number;
  country: string;
  statusC: number;
  productName: string;
  csId: string;
  createDate: string;
  productDescription: string;
  visit: number;
  account: string;
  actionCode: string;
  coverageCode: string;
  insuranceCode: string;
  invoiceMonths: number;
  productCode: string;
  productDetails: string;
  operation: string;
  version: number;
  dateStart: string;
  dateEnd: string;
  cost: number;
  sellerPrice: number;
  customerPrice:number;
  months: number;
  kilometers: number;
  planYear: number;
  
}