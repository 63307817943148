export enum SalesTypeFordEnum {

  RETAIL_CODE = '5', 
  RETAIL_DESCRIPTION = "Varejo",
	DIRECT_CODE = '10',
	DIRECT_DESCRIPTION = "Direta",
	GOVERNMENT_CODE = '15',
	GOVERNMENT_DESCRIPTION = "Governo",
	FREE_CONTRACT_CODE = '20',
	FREE_CONTRACT_DESCRIPTION = "Free Contract",
	FORD_FLEET_CODE = '25',
	FORD_FLEET_DESCRIPTION = "Frota Interna",
	FORD_GO_CODE = '26',
	FORD_GO_DESCRIPTION = "FordGO",
	TELEMARKETING_CODE = '30', 
	TELEMARKETING_DESCRIPTION = "Telemarketing"
}