import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng';
import { ConstantsFordProtect } from 'src/app/models/constants/constants-ford-protect';
import { CoverageCategoryEdit } from 'src/app/models/product/coverage-category-edit';
import { ApsPermissionService } from 'src/app/services/aps-permission/aps-permission.service';
import { CoverageService } from 'src/app/services/coverage/coverage.service';

@Component({
  selector: 'coverage-details-edit',
  templateUrl: './coverage-details-edit.component.html',
  styleUrls: ['./coverage-details-edit.component.scss']
})
export class CoverageDetailsEditComponent implements OnInit {

  inputCoverageName: string;
  inputCoverageDescription: string;
  selectTypeCoverage: string;
  inputOwsCoverage: string;
  inputMonths: number;
  inputDistance: number;
  inputTotalVisits: number;
  inputInitialVisit: number;
  inputAccount: string;
  inputActionCode: string;
  inputCoverageCode: string;
  inputInsuranceCode: string;
  coverage: CoverageCategoryEdit;
  editedCoverage: CoverageCategoryEdit = {} as CoverageCategoryEdit;
  productName: string;
  blockedUi: boolean = false;
  editionEnabled: boolean = true;

  selectDisabled: boolean;

  listTypeCoverage = [
    { label: 'TRANSLATE.SELECT' },
    { label: 'TRANSLATE.UPPERCASE.MAINTENANCE', value: ConstantsFordProtect.MAINTENANCE },
    { label: 'TRANSLATE.UPPERCASE.EXTENSION', value: ConstantsFordProtect.EXTENSION },
    { label: 'TRANSLATE.UPPERCASE.REPAIRS', value: ConstantsFordProtect.REPAIRS },
    { label: 'TRANSLATE.UPPERCASE.SIGNALS', value: ConstantsFordProtect.SIGNALS },
    { label: 'TRANSLATE.UPPERCASE.WINCHES', value: ConstantsFordProtect.WINCHES },
    { label: 'TRANSLATE.UPPERCASE.WEAR', value: ConstantsFordProtect.WEAR }
  ];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private coverageService: CoverageService,
    private apsPermissionService: ApsPermissionService,
    private translateService: TranslateService,
    private messageService: MessageService,
  ) {
    this.route.paramMap.subscribe(params => {
      this.id = +params.get('id');
    })
  }

  id: number;

  ngOnInit() {

    this.buttonCancel();
    this.getCoverageById();

  }

  buttonReturn() {
    this.router.navigate(['view-plans'], { skipLocationChange: false, replaceUrl: false });
  }

  buttonEdit() {

    (<HTMLInputElement>document.getElementById("coverageIdName")).disabled = false;
    (<HTMLInputElement>document.getElementById("coverageDescription")).disabled = false;
    this.selectDisabled = false;
    (<HTMLInputElement>document.getElementById("typeCoverage")).disabled = false;
    (<HTMLInputElement>document.getElementById("owsCoverage")).disabled = false;
    (<HTMLInputElement>document.getElementById("months")).disabled = false;
    (<HTMLInputElement>document.getElementById("distance")).disabled = false;
    (<HTMLInputElement>document.getElementById("totalVisits")).disabled = false;
    (<HTMLInputElement>document.getElementById("initialVisit")).disabled = false;
    (<HTMLInputElement>document.getElementById("accountCode")).disabled = false;
    (<HTMLInputElement>document.getElementById("actionCode")).disabled = false;
    (<HTMLInputElement>document.getElementById("coverageCode")).disabled = false;
    (<HTMLInputElement>document.getElementById("insuranceCode")).disabled = false;
    (<HTMLInputElement>document.getElementById("titleDetails")).style.display = 'none';
    (<HTMLInputElement>document.getElementById("titleEdit")).style.display = 'block';
    (<HTMLInputElement>document.getElementById("subTitle")).style.display = 'block';
    (<HTMLInputElement>document.getElementById("btnReturn")).style.display = 'none';
    (<HTMLInputElement>document.getElementById("btnEdit")).style.display = 'none';
    (<HTMLInputElement>document.getElementById("btnCancel")).style.display = 'block';
    (<HTMLInputElement>document.getElementById("btnSave")).style.display = 'block';


  }

  buttonCancel() {

    (<HTMLInputElement>document.getElementById("coverageIdName")).disabled = true;
    (<HTMLInputElement>document.getElementById("coverageDescription")).disabled = true;
    this.selectDisabled = true;
    (<HTMLInputElement>document.getElementById("typeCoverage")).disabled = true;
    (<HTMLInputElement>document.getElementById("owsCoverage")).disabled = true;
    (<HTMLInputElement>document.getElementById("months")).disabled = true;
    (<HTMLInputElement>document.getElementById("distance")).disabled = true;
    (<HTMLInputElement>document.getElementById("totalVisits")).disabled = true;
    (<HTMLInputElement>document.getElementById("initialVisit")).disabled = true;
    (<HTMLInputElement>document.getElementById("accountCode")).disabled = true;
    (<HTMLInputElement>document.getElementById("actionCode")).disabled = true;
    (<HTMLInputElement>document.getElementById("coverageCode")).disabled = true;
    (<HTMLInputElement>document.getElementById("insuranceCode")).disabled = true;

    (<HTMLInputElement>document.getElementById("titleDetails")).style.display = 'block';
    (<HTMLInputElement>document.getElementById("titleEdit")).style.display = 'none';
    (<HTMLInputElement>document.getElementById("subTitle")).style.display = 'none';
    (<HTMLInputElement>document.getElementById("btnReturn")).style.display = 'block';
    (<HTMLInputElement>document.getElementById("btnEdit")).style.display = 'block';
    (<HTMLInputElement>document.getElementById("btnCancel")).style.display = 'none';
    (<HTMLInputElement>document.getElementById("btnSave")).style.display = 'none';

    this.restoringValues();

  }

  optionCoverageTypeChange(event: any) {
    this.selectTypeCoverage = event.value;
  }

  months(months: any) {
    this.inputMonths = this.formatNumber(months);
  }

  distance(distance: any) {
    this.inputDistance = this.formatNumber(distance);
  }

  totalVisits(totalVisits: any) {
    this.inputTotalVisits = this.formatNumber(totalVisits);
  }

  initialVisit(initialVisit: any) {
    this.inputInitialVisit = this.formatNumber(initialVisit);
  }

  formatNumber(event: any) {
    const input = event.target;
    let value = input.value;
    const valueFormat = value.replace(/[^0-9]/g, '');

    input.value = valueFormat;
    return valueFormat;
  }

  buttonSave() {

    this.editionEnabled = true;
    this.blockedUi = true;
    this.inputCoverageName = (<HTMLInputElement>document.getElementById("coverageIdName")).value;
    this.inputCoverageDescription = (<HTMLInputElement>document.getElementById("coverageDescription")).value;
    this.inputCoverageCode = (<HTMLInputElement>document.getElementById("coverageCode")).value;
    this.inputOwsCoverage = (<HTMLInputElement>document.getElementById("owsCoverage")).value;
    this.selectTypeCoverage = this.selectTypeCoverage;
    this.inputMonths = Number((<HTMLInputElement>document.getElementById("months")).value);
    this.inputDistance = Number((<HTMLInputElement>document.getElementById("distance")).value);
    this.inputTotalVisits = Number((<HTMLInputElement>document.getElementById("totalVisits")).value);
    this.inputInitialVisit = Number((<HTMLInputElement>document.getElementById("initialVisit")).value);
    this.inputAccount = (<HTMLInputElement>document.getElementById("accountCode")).value;
    this.inputActionCode = (<HTMLInputElement>document.getElementById("actionCode")).value;
    this.inputInsuranceCode = (<HTMLInputElement>document.getElementById("insuranceCode")).value;



    if (this.inputCoverageName === undefined ||
      this.inputCoverageName.length < 4 ||
      this.inputCoverageName.length > 20) {

      this.inputCoverageName = null;
      this.blockedUi = false;
      this.editionEnabled = false;
    }
    if (this.inputCoverageDescription === undefined ||
      this.inputCoverageDescription.length < 4 ||
      this.inputCoverageDescription.length > 100) {

      this.inputCoverageDescription = null;
      this.blockedUi = false;
      this.editionEnabled = false;
    }
    if (this.inputOwsCoverage === undefined ||
      this.inputOwsCoverage.length < 4 ||
      this.inputOwsCoverage.length > 8) {

      this.inputOwsCoverage = null;
      this.blockedUi = false;
      this.editionEnabled = false;
    }
    if (this.selectTypeCoverage === "" || this.selectTypeCoverage === undefined) {

      this.selectTypeCoverage = null;
      this.blockedUi = false;
      this.editionEnabled = false;
    }
    if (this.inputMonths === undefined ||
      this.inputMonths <= 0 ||
      this.inputMonths >= 100) {

      this.inputMonths = null;
      this.blockedUi = false;
      this.editionEnabled = false;
    }
    if (this.inputDistance === undefined ||
      this.inputDistance <= 0 ||
      this.inputDistance >= 1000000) {
      this.inputDistance = null;
      this.blockedUi = false;
      this.editionEnabled = false;
    }
    if (this.inputTotalVisits === undefined ||
      this.inputTotalVisits <= 0 ||
      this.inputTotalVisits >= 100
    ) {
      this.inputTotalVisits = null;
      this.blockedUi = false;
      this.editionEnabled = false;
    }
    if (this.inputInitialVisit === undefined ||
      this.initialVisit.length < 1 ||
      this.initialVisit.length > 2) {

      this.inputInitialVisit = null;
      this.blockedUi = false;
      this.editionEnabled = false;
    }
    if (this.inputAccount === undefined ||
      this.inputAccount.length > 9 || this.inputAccount.length <= 0) {

      this.inputAccount = null;
      this.blockedUi = false;
      this.editionEnabled = false;

    }
    if (this.inputCoverageCode === undefined ||
      this.inputCoverageCode.length > 4 || this.inputCoverageCode.length <= 0) {

      this.inputCoverageCode = null;
      this.blockedUi = false;
      this.editionEnabled = false;

    }
    if (this.inputActionCode === undefined ||
      this.inputActionCode.length > 1 || this.inputActionCode.length <= 0) {

      this.inputActionCode = null;
      this.blockedUi = false;
      this.editionEnabled = false;

    }
    if (this.inputInsuranceCode === undefined ||
      this.inputInsuranceCode.length > 4 || this.inputInsuranceCode.length <= 0) {
      this.inputInsuranceCode = null;
      this.blockedUi = false;
      this.editionEnabled = false;
    }
    else if (this.editionEnabled) {

      this.editedCoverage.id = this.coverage.id;
      this.editedCoverage.name = this.inputCoverageName;
      this.editedCoverage.description = this.inputCoverageDescription;
      this.editedCoverage.visit = this.inputTotalVisits;
      this.editedCoverage.startVisit = this.inputInitialVisit;
      this.editedCoverage.planYear = this.coverage.planYear;
      this.editedCoverage.account = this.inputAccount;
      this.editedCoverage.actionCode = this.inputActionCode;
      this.editedCoverage.coverageCode = this.inputCoverageCode;
      this.editedCoverage.insuranceCode = this.inputInsuranceCode;
      this.editedCoverage.productId = this.coverage.productId;
      this.editedCoverage.productName = this.coverage.productName;
      this.editedCoverage.productCode = this.coverage.productCode;
      this.editedCoverage.country = this.coverage.country;
      this.editedCoverage.type = this.selectTypeCoverage;
      this.editedCoverage.months = this.inputMonths;
      this.editedCoverage.owsCoverage = this.inputOwsCoverage;
      this.editedCoverage.updateUser = this.apsPermissionService.getUserCsid();
      this.editedCoverage.distance = this.inputDistance;
      this.editCoverage(this.editedCoverage);


    }

  }

  getCoverageById() {

    this.blockedUi = true;
    this.coverageService.getSearchById(this.id).subscribe(
      (response: CoverageCategoryEdit) => {
        this.coverage = response;
        this.productName = this.coverage.productId + " - " + this.coverage.productName + " - " + this.coverage.productCode;
        this.blockedUi = false;
        this.selectTypeCoverage = this.coverage.type;
      })
  }

  editCoverage(editedCoverage: CoverageCategoryEdit) {

    this.coverageService.updateCoverage(editedCoverage).subscribe(
      response => {
        this.buttonCancel();
        this.getCoverageById();
        this.messageService.add({
          severity: 'success',
          summary: this.translateService.instant('TRANSLATE.SUCCESS'),
          detail: this.translateService.instant('TRANSLATE.SUCCESS.EDIT')
        });
        this.blockedUi = false;
      }, (err) => {
        this.blockedUi = false;
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('TRANSLATE.ERROR'),
          detail: this.translateService.instant('TRANSLATE.ERROR.SAVE.CHANGES')
        });
      }
    )

  }

  restoringValues() {

    this.inputCoverageName = '';
    this.inputCoverageDescription = '';
    this.inputOwsCoverage = '';
    this.selectTypeCoverage = '';
    this.inputMonths = undefined;
    this.inputDistance = undefined;
    this.inputTotalVisits = undefined;
    this.inputInitialVisit = undefined;
  }

}
