export class NewCoverageCategory {
  
  id: number;
  productId: number; 
  planYear: string;
  accountNumber: string;
  actionCode: string;
  coverageCode: string;
  insuranceCode: string;  
  name: string;
  description: string;
  type: string;
  owsCoverage:string;
  country: string;
  months: string;
  distance: number;
  distanceUnit: string
  visit: number;
  startVisit: number;
  termUnit: string;
  createUser: string

}