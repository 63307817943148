import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng';
import { ConstantsFordProtect } from 'src/app/models/constants/constants-ford-protect';
import { Product } from 'src/app/models/product/product';
import { ProductEdit } from 'src/app/models/product/product-edit';
import { ApsPermissionService } from 'src/app/services/aps-permission/aps-permission.service';
import { ProductService } from 'src/app/services/product/product.service';

@Component({
  selector: 'product-details-edit',
  templateUrl: './product-details-edit.component.html',
  styleUrls: ['./product-details-edit.component.scss']
})
export class ProductDetailsEditComponent implements OnInit {

  inputNameMessage: boolean;
  inputDescriptionMessage: boolean;
  inputStatusMessage: boolean;
  inputVisitMessage: boolean;
  inputCodeMessage: boolean;
  inputAccountCodeMessage: boolean;
  inputActionCodeMessage: boolean;
  inputCoverageCodeMessage: boolean;
  inputInsuranceCodeMessage: boolean;
  inputProductDetailsMessage: boolean;
  inputinvoiceMonthsMessage: boolean;
  inputProductName: string;
  inputProductDescription: string;
  inputProductStatus: string;
  inputProductVisit: number;
  inputProductCode: string;
  inputProductAccountCode: string;
  inputProductActionCode: string;
  inputProductCoverageCode: string;
  inputProductInsuranceCode: string;
  inputProductProductDetails: string;
  inputProductinvoiceMonths: number;

  actionCodeUppercase: string;
  accountCodeUppercase: string;
  coverageUppercase: string;
  insuranceCodeUppercase: string;
  id: number;
  product: ProductEdit = {} as ProductEdit;
  editedProduct: ProductEdit = {} as ProductEdit;
  blockedUi: boolean = false;
  selectedStatus: any;
  selectDisabled: boolean = false;


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private productService: ProductService,
    private apsPermissionService: ApsPermissionService,
    private messageService: MessageService,
    public translateService: TranslateService,

  ) {
    this.route.paramMap.subscribe(params => {
      this.id = +params.get('id');
    });
  }


  listStatus = [
		{ label: 'TRANSLATE.SELECT' },
		{ label: 'TRANSLATE.ACTIVE', value: ConstantsFordProtect.PRODUCT_ACTIVE },
		{ label: 'TRANSLATE.INACTIVE', value: ConstantsFordProtect.PRODUCT_INACTIVE }
  ];
  
  ngOnInit() {

    this.buttonCancel();
    this.blockedUi = true;
    this.searchProduct(this.id);
  }

  buttonReturn() {
    this.router.navigate(['view-plans'], { skipLocationChange: false, replaceUrl: false });
  }

  buttonEdit() {

    (<HTMLInputElement>document.getElementById("productName")).disabled = false;
    (<HTMLInputElement>document.getElementById("productStatus")).disabled = false;
    (<HTMLInputElement>document.getElementById("productCode")).disabled = false;
    (<HTMLInputElement>document.getElementById("visit")).disabled = false;
    (<HTMLInputElement>document.getElementById("productDescription")).disabled = false;
    (<HTMLInputElement>document.getElementById("account")).disabled = false;
    (<HTMLInputElement>document.getElementById("actionCode")).disabled = false;
    (<HTMLInputElement>document.getElementById("coverageCode")).disabled = false;
    (<HTMLInputElement>document.getElementById("insuranceCode")).disabled = false;
    (<HTMLInputElement>document.getElementById("productDetails")).disabled = false;
    (<HTMLInputElement>document.getElementById("invoiceMonths")).disabled = false;
    (<HTMLInputElement>document.getElementById("titleDetails")).style.display = 'none';
    (<HTMLInputElement>document.getElementById("titleEdit")).style.display = 'block';
    (<HTMLInputElement>document.getElementById("subTitle")).style.display = 'block';
    (<HTMLInputElement>document.getElementById("btnReturn")).style.display = 'none';
    (<HTMLInputElement>document.getElementById("btnEdit")).style.display = 'none';
    (<HTMLInputElement>document.getElementById("btnCancel")).style.display = 'block';
    (<HTMLInputElement>document.getElementById("btnSave")).style.display = 'block';
    this.selectDisabled = false;
  }

  buttonCancel() {

    (<HTMLInputElement>document.getElementById("productName")).disabled = true;
    (<HTMLInputElement>document.getElementById("productStatus")).disabled = true;
    (<HTMLInputElement>document.getElementById("productCode")).disabled = true;
    (<HTMLInputElement>document.getElementById("visit")).disabled = true;
    (<HTMLInputElement>document.getElementById("productDescription")).disabled = true;
    (<HTMLInputElement>document.getElementById("account")).disabled = true;
    (<HTMLInputElement>document.getElementById("actionCode")).disabled = true;
    (<HTMLInputElement>document.getElementById("coverageCode")).disabled = true;
    (<HTMLInputElement>document.getElementById("insuranceCode")).disabled = true;
    (<HTMLInputElement>document.getElementById("productDetails")).disabled = true;
    (<HTMLInputElement>document.getElementById("invoiceMonths")).disabled = true;
    (<HTMLInputElement>document.getElementById("titleDetails")).style.display = 'block';
    (<HTMLInputElement>document.getElementById("titleEdit")).style.display = 'none';
    (<HTMLInputElement>document.getElementById("subTitle")).style.display = 'none';
    (<HTMLInputElement>document.getElementById("btnReturn")).style.display = 'block';
    (<HTMLInputElement>document.getElementById("btnEdit")).style.display = 'block';
    (<HTMLInputElement>document.getElementById("btnCancel")).style.display = 'none';
    (<HTMLInputElement>document.getElementById("btnSave")).style.display = 'none';
    this.selectDisabled = true;


    this.inputNameMessage = false;
    this.inputDescriptionMessage = false;
    this.inputStatusMessage = false;
    this.inputVisitMessage = false;
    this.inputCodeMessage = false;
    this.inputAccountCodeMessage = false;
    this.inputActionCodeMessage = false;
    this.inputCoverageCodeMessage = false;
    this.inputInsuranceCodeMessage = false;
    this.inputProductDetailsMessage = false;
    this.inputinvoiceMonthsMessage = false;

  }

  accountOnKeyUp(event: any) {
    this.accountCodeUppercase = event.target.value.toUpperCase();
  }

  actionCodeOnKeyUp(event: any) {
    this.actionCodeUppercase = event.target.value.toUpperCase();
  }

  coverageCodeOnKeyUp(event: any) {
    this.coverageUppercase = event.target.value.toUpperCase();
  }

  insuranceCodeOnKeyUp(event: any) {
    this.insuranceCodeUppercase = event.target.value.toUpperCase();
  }

  statusChange(event: any) {
		this.selectedStatus = event.value;
	}

  buttonSave() {

    this.blockedUi = true;

    this.inputProductName = (<HTMLInputElement>document.getElementById("productName")).value;
    this.inputProductDescription = (<HTMLSelectElement>document.getElementById("productDescription")).value;
    this.inputProductStatus = this.selectedStatus
    this.inputProductVisit = Number(Number((<HTMLInputElement>document.getElementById("visit")).value));
    this.inputProductCode = (<HTMLInputElement>document.getElementById("productCode")).value;
    this.inputProductAccountCode = (<HTMLInputElement>document.getElementById("account")).value;
    this.inputProductActionCode = (<HTMLInputElement>document.getElementById("actionCode")).value;
    this.inputProductCoverageCode = (<HTMLInputElement>document.getElementById("coverageCode")).value;
    this.inputProductInsuranceCode = (<HTMLInputElement>document.getElementById("insuranceCode")).value;
    this.inputProductProductDetails = (<HTMLInputElement>document.getElementById("productDetails")).value;
    this.inputProductinvoiceMonths = Number((<HTMLInputElement>document.getElementById("invoiceMonths")).value);

    if (this.inputProductName === null || this.inputProductName.length < 4 || this.inputProductName.length > 50) { this.inputNameMessage = true;
      this.blockedUi = false;
    }
    else if (this.inputProductDescription === null || this.inputProductDescription.length < 4 || this.inputProductDescription.length > 300) { this.inputDescriptionMessage = true;
      this.blockedUi = false; }
    else if (this.selectedStatus === null) { this.inputStatusMessage = true;
      this.blockedUi = false; }
    else if (this.inputProductVisit === null || this.inputProductVisit < 1 || this.inputProductVisit > 99) { this.inputVisitMessage = true;
      this.blockedUi = false; }
    else if (this.inputProductCode === null || this.inputProductCode.length < 1 || this.inputProductCode.length > 10) { this.inputCodeMessage = true; 
      this.blockedUi = false; }
    else if (this.inputProductAccountCode === null || this.inputProductAccountCode.length > 9) { this.inputAccountCodeMessage = true; 
      this.blockedUi = false; }
    else if (this.inputProductActionCode === null || this.inputProductActionCode.length > 1) { this.inputActionCodeMessage = true;
      this.blockedUi = false; }
    else if (this.inputProductCoverageCode === null || this.inputProductCoverageCode.length > 4) { this.inputCoverageCodeMessage = true; 
      this.blockedUi = false; }
    else if (this.inputProductInsuranceCode === null || this.inputProductInsuranceCode.length > 4) { this.inputInsuranceCodeMessage = true;
       this.blockedUi = false; }
    else if (this.inputProductProductDetails === null) { this.inputProductDetailsMessage = true; this.blockedUi = false;
     }
    else if (this.inputProductinvoiceMonths === null || this.inputProductinvoiceMonths > 999 || this.inputProductinvoiceMonths < 0 || !(/^([0-9])$/.test((<HTMLInputElement>document.getElementById("invoiceMonths")).value))) { this.inputinvoiceMonthsMessage = true;
       this.blockedUi = false; }
    else {

      this.inputNameMessage = false;
      this.inputDescriptionMessage = false;
      this.inputStatusMessage = false;
      this.inputVisitMessage = false;
      this.inputCodeMessage = false;
      this.inputAccountCodeMessage = false;
      this.inputActionCodeMessage = false;
      this.inputCoverageCodeMessage = false;
      this.inputInsuranceCodeMessage = false;
      this.inputProductDetailsMessage = false;
      this.inputinvoiceMonthsMessage = false;

      this.editedProduct.id = this.product.id;
      this.editedProduct.name = this.inputProductName;
      this.editedProduct.status = this.selectedStatus;
      this.editedProduct.country = this.product.country;
      this.editedProduct.code = this.inputProductCode;
      this.editedProduct.description = this.inputProductDescription;
      this.editedProduct.visit = this.inputProductVisit;
      this.editedProduct.months = this.inputProductinvoiceMonths;
      this.editedProduct.accountCode = this.inputProductAccountCode;
      this.editedProduct.actionCode = this.inputProductActionCode;
      this.editedProduct.coverageCode = this.inputProductCoverageCode;
      this.editedProduct.insuranceCode = this.inputProductInsuranceCode;
      this.editedProduct.details = this.inputProductProductDetails;
      this.editedProduct.updateUser = this.apsPermissionService.getUserCsid();

      this.buttonCancel();
      this.productService.updateProduct(this.editedProduct).subscribe(
        (response: Product) => {
          this.blockedUi = false;
          this.searchProduct(this.editedProduct.id)
          this.messageService.add({
            severity: 'success',
            summary: this.translateService.instant('TRANSLATE.SUCCESS'),
            detail: this.translateService.instant('TRANSLATE.SUCCESS.EDIT')
          })
        }, (err) => {
          this.blockedUi = false;
          this.searchProduct(this.editedProduct.id)
          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('TRANSLATE.ERROR'),
            detail: this.translateService.instant('TRANSLATE.ERROR.SAVE.CHANGES')
          });
        }
      );
    }
  }

  searchProduct(id: number) {
    this.productService.getProductById(id).subscribe(
      (response) => {
        this.product.id = response.id;
        this.product.name = response.name;
        this.product.country = response.country;
        this.product.code = response.productCode;
        this.selectedStatus = ((response.status == "25") ? ConstantsFordProtect.PRODUCT_ACTIVE : ConstantsFordProtect.PRODUCT_INACTIVE);
        this.product.description = response.description;
        this.product.visit = response.visit;
        this.product.months = response.invoiceMonths;
        this.product.accountCode = response.accountNumber;
        this.product.actionCode = response.actionCode;
        this.product.coverageCode = response.coverageCode;
        this.product.insuranceCode = response.insuranceCode;
        this.product.createDate = response.createDate;
        this.product.details = response.productDetails;
        this.blockedUi = false;
      }
    )
  }

  private convertDateNeverTimezone(date: Date): Date {
    return new Date(date + 'T00:00:00.00');
  }

}
