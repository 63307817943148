import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng';
import { ApsPermitedScreen } from 'src/app/models/aps-permission/aps-permited-screen';
import { ConstantsFordProtect } from 'src/app/models/constants/constants-ford-protect';
import { NewPrice } from 'src/app/models/product/new-price';
import { ApsPermissionService } from 'src/app/services/aps-permission/aps-permission.service';
import { PriceService } from 'src/app/services/price/price.service';

@Component({
  selector: 'new-price',
  templateUrl: './new-price.component.html',
  styleUrls: ['./new-price.component.scss']
})
export class NewPriceComponent implements OnInit {

  listNamePrice = [
    { label: 'TRANSLATE.SELECT' },
    { label: 'TRANSLATE.SELECT.PRICE.CUSTOMER', value: ConstantsFordProtect.PRICE_CUSTOMER },
    { label: 'TRANSLATE.SELECT.SELLER.PRICE', value: ConstantsFordProtect.SELLER_PRICE },
    { label: 'TRANSLATE.SELECT.COST.FORD', value: ConstantsFordProtect.COST_FORD }
  ];

  listCurrency = [
    { label: 'TRANSLATE.SELECT' },
    { label: 'TRANSLATE.SELECT.COUNTRY.CURRENCY.BRA', value: ConstantsFordProtect.REAL },
    { label: 'TRANSLATE.SELECT.COUNTRY.CURRENCY.OTHERS', value: ConstantsFordProtect.PESO }
  ];

  userform: FormGroup;
  selectedNamePrice: string;
  selectedCurrency: string;
  inputPrice: string;
  finalPrice: number;

  constructor(
    private fb: FormBuilder,
    private messageService: MessageService,
    private apsPermissionService: ApsPermissionService,
    private translateService: TranslateService,
    private priceService: PriceService,
    private router: Router
  ) { }

  ngOnInit() {
    this.apsPermissionService.permissionValidation(ApsPermitedScreen.screenViewsPlans);

    this.userform = this.fb.group({
      'optionNamePrice': [null, Validators.required],
      'optionCurrency': [null, Validators.required],
      'inputPriceValue': ['', [Validators.required, Validators.min(1), Validators.maxLength(13), Validators.pattern('^[0-9,.]*$')]],
      'inputPriceDescription': ['', [Validators.required, Validators.minLength(4), Validators.maxLength(100)]]
    });
  }

  formatNumber(event: any) {

    const input = event.target as HTMLInputElement;
    let priceTreatment = input.value;
    
    priceTreatment = priceTreatment.replace(/\D/g, ""); 
    priceTreatment = priceTreatment.replace(/(\d+)(\d{2})$/, "$1,$2"); 
	  priceTreatment = priceTreatment.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
	
    input.value = priceTreatment;

    let valueWithoutPoint = priceTreatment.replace(/\./g, '').replace(',', '.');
    this.inputPrice = valueWithoutPoint; 
  }

  buttonReturn() {
    this.router.navigate(['view-plans'], { skipLocationChange: false, replaceUrl: false });
  }

  namePriceChange(event: any) {
    this.selectedNamePrice = event.value;
  }

  currencyChange(event: any) {
    this.selectedCurrency = event.value;
  }

  buttonSave() {

    if (this.selectedNamePrice === undefined || this.selectedNamePrice === null) {
      this.userform.get('optionNamePrice').markAsTouched();
      this.selectedNamePrice = null;
    }
    if (this.selectedCurrency === undefined || this.selectedCurrency === null) {
      this.userform.get('optionCurrency').markAsTouched();
      this.selectedCurrency = null;
    }
    if (this.userform.get('inputPriceValue').invalid) {
      this.userform.get('inputPriceValue').markAsPending();
    }
    if (this.userform.get('inputPriceDescription').invalid) {
      this.userform.get('inputPriceDescription').markAsPending();
    }
    if (this.userform.valid) {

      var itemPrice = new NewPrice();
      this.finalPrice = parseFloat(this.inputPrice);
      itemPrice.itemName = this.selectedNamePrice;
      itemPrice.itemPriceUnit = this.selectedCurrency;
      itemPrice.itemPrice = this.finalPrice;
      itemPrice.itemDescription = this.userform.get('inputPriceDescription').value;
      itemPrice.createUser = this.apsPermissionService.getUserCsid();

      this.priceService.createNewPrice(itemPrice).subscribe((response) => {
        this.messageService.add({
          severity: 'success',
          summary: this.translateService.instant('TRANSLATE.SUCCESS'),
          detail: this.translateService.instant('TRANSLATE.SUCCESS.CREATE')
        });

        this.buttonReturn();
      }, (err) => {
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('TRANSLATE.ERROR'),
          detail: this.translateService.instant('TRANSLATE.ERROR.CREATE.PRICE')
        });
      });

    } else {

      this.messageService.add({
        severity: 'error',
        summary: this.translateService.instant('TRANSLATE.ERROR'),
        detail: this.translateService.instant('TRANSLATE.ERROR.PLEASE.REQUIRED.FIELDS')
      });
    }
  }
}
