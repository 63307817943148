 export enum StatusContract {

  ACTIVE = 5,
	BLOCKED = 10,
	CANCELLED = 15,
	FINISHED = 20,
	CREATED = 25,
	PENDING_PAYMENT = 30,
	PENDING_CANCELLATION = 55,
	PENDING_ACTIVATION = 60,
	PENDING_AMENDMENT = 65,
	WITH_ERROR = 95	
 }