import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MessageService } from 'primeng';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CoverageCategory } from 'src/app/models/product/coverage-category';
import { CoverageCategoryEdit } from 'src/app/models/product/coverage-category-edit';
import { CoverageCategoryOws } from 'src/app/models/product/coverage-category-ows';
import { NewCoverageCategory } from 'src/app/models/product/new-coverage-category';
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class CoverageService {

  private apiUrl = environment.apiUrl;
  private country: string = 'BRA';
  private operation: string = 'CAR';

  constructor(
    private httpClient: HttpClient, 
    private messageService: MessageService
  ) {
  }

  getAllCoverages(): Observable<Array<CoverageCategoryOws>> {
    return this.httpClient.get(`${this.apiUrl}/api/v1/coverage-categories/search`)
      .pipe(
        map((response: Array<CoverageCategoryOws>) => {
          return response;
        })
      );
  }

  getCoveragesByCountry(countries: Array<string>): Observable<Array<CoverageCategoryOws>> {
    return this.httpClient.post<Array<CoverageCategoryOws>>(`${this.apiUrl}/api/v1/coverage-categories/search/countries`, countries, httpOptions)
      .pipe(
        map((response: Array<CoverageCategoryOws>) => {
          return response;
        })
      );
  }
  
  getSearchCoverageCategoryAndProductId(productId: number): Observable<Array<CoverageCategory>> {
    return this.httpClient.get<Array<CoverageCategory>>(`${this.apiUrl}/api/v1/coverage-categories/search/${productId}`)
      .pipe(
        map((response: Array<CoverageCategory>) => {
          return response;
        })
      );
  }

  createCoverage(newCoverageCategoryDto: NewCoverageCategory): Observable<NewCoverageCategory> {
    return this.httpClient.post<NewCoverageCategory>(`${this.apiUrl}/api/v1/coverage-categories`, newCoverageCategoryDto, httpOptions);
  }

  getSearchByProductId(productId: number): Observable<Array<CoverageCategory>> {
    return this.httpClient.get<Array<CoverageCategory>>(`${this.apiUrl}/api/v1/coverage-categories/product-id/${productId}`)
      .pipe(
        map((response: Array<CoverageCategory>) => {
          return response;
        })
      );
  }

  getSearchById(productId: number): Observable<CoverageCategoryEdit> {
    return this.httpClient.get<CoverageCategoryEdit>(`${this.apiUrl}/api/v1/coverage-categories/search/coverageid/${productId}`)
      .pipe(
        map((response: CoverageCategoryEdit) => {
          return response;
        })
      );
  }

  updateCoverage(coverage: CoverageCategoryEdit): Observable<CoverageCategory> {
    return this.httpClient.put<CoverageCategory>(`${this.apiUrl}/api/v1/coverage-categories`, coverage, httpOptions);
  }
  
}
