import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { LazyLoadEvent, MessageService } from 'primeng';
import { ApsPermitedScreen } from 'src/app/models/aps-permission/aps-permited-screen';
import { ConstantsFordProtect } from 'src/app/models/constants/constants-ford-protect';
import { FilterContractSearch } from 'src/app/models/contract/filter-contract-search';
import { FilterContractSearchResult } from 'src/app/models/contract/filter-contract-search-result';
import { FilterContractSearchResultSimplified } from 'src/app/models/contract/filter-contract-search-result-simplified';
import { FilterContractSearchResultTotalRecord } from 'src/app/models/contract/filter-contract-search-result-total-record';
import { SalesTypeFord } from 'src/app/models/contract/sales-type-ford';
import { DealerApsFordDetailAttributes } from 'src/app/models/dealer/dealer-aps-ford-detail-attributes';
import { OperationCode } from 'src/app/models/enum/operation-code';
import { StatusContract } from 'src/app/models/enum/status-contract';
import { ProductActive } from 'src/app/models/product/product-active';
import { ApsPermissionService } from 'src/app/services/aps-permission/aps-permission.service';
import { ContractService } from 'src/app/services/contract/contract.service';
import { DealerService } from 'src/app/services/dealer/dealer.service';
import { ProductService } from 'src/app/services/product/product.service';
import { listStatusType } from 'src/assets/functions/list_status_type';

@Component({
	selector: 'app-contract-search-dealer',
	templateUrl: './contract-search-dealer.component.html',
	styleUrls: ['./contract-search-dealer.component.scss']
})
export class ContractSearchDealerComponent implements OnInit {

	pt = {
		firstDayOfWeek: 0,
		dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
		dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
		dayNamesMin: ['Do', 'Se', 'Te', 'Qu', 'Qu', 'Se', 'Sa'],
		monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho',
		'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
		monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
		today: 'Hoje',
		clear: 'Limpar'
	};

	es = {
		firstDayOfWeek: 0,
		dayNames: ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sábado'],
		dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
		dayNamesMin: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
		monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio',
		'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
		monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
		today: 'Hoy',
		clear: 'Limpar'
	};
	
	calendarLang = this.pt;

	statusFilter = [
		{label:'TRANSLATE.SELECT'},
		{ label: 'TRANSLATE.ACTIVE', value: StatusContract.ACTIVE },
		{ label: 'TRANSLATE.BLOCKED', value: StatusContract.BLOCKED },
		{ label: 'TRANSLATE.CANCELED', value: StatusContract.CANCELLED },
		{ label: 'TRANSLATE.FINISHED', value: StatusContract.FINISHED },
		{ label: 'TRANSLATE.CREATED', value: StatusContract.CREATED },
		{ label: 'TRANSLATE.PENDING.PAYMENT', value: StatusContract.PENDING_PAYMENT },
		{ label: 'TRANSLATE.PENDING.CANCELLATION', value: StatusContract.PENDING_CANCELLATION },
		{ label: 'TRANSLATE.PENDING.ACTIVATION', value: StatusContract.PENDING_ACTIVATION },
		{ label: 'TRANSLATE.PENDING.AMENDMENT', value: StatusContract.PENDING_AMENDMENT },
		{ label: 'TRANSLATE.WITH.ERROR', value: StatusContract.WITH_ERROR }
	];

	countryFilter = [
		{label: 'TRANSLATE.BRAZIL', value: 'BRA'},
		{label: 'TRANSLATE.ARGENTINA', value: 'ARG'}
	];

	globalContractFilter = [
		{label:'TRANSLATE.SELECT', value: 0},
		{label:'TRANSLATE.YES', value: 1},
		{label:'TRANSLATE.NOT', value: 2}
	];

	blockedUi: boolean = false;

	salesTypeFords: Array<SalesTypeFord> = [];
	salesTypeFord: SalesTypeFord;

	productActiveCountries: Array<ProductActive> = [];
	productActive: ProductActive;

	filterContractSearch : FilterContractSearch;
	filterContractSearchResultList: Array<FilterContractSearchResult> = [];
	filterContractSearchResultTotalRecord: FilterContractSearchResultTotalRecord;
	contractSearchTotalRecord: number;
	isSubmitted: boolean = false;
	isSubmittedExcel: boolean = false;
	cdsid: string = '';
	nameDealer = '';

	userCountry: string;

	displayModalPdf: boolean = false;
	documentType: string;
	contractId: number;

	constructor(private translateService: TranslateService, private messageService: MessageService,
		private router: Router, private apsPermissionService: ApsPermissionService,
		private contractService: ContractService,  private productService: ProductService,
		private dealerService: DealerService) {

			this.filterContractSearch = new FilterContractSearch();
			this.filterContractSearchResultTotalRecord = new FilterContractSearchResultTotalRecord();
			this.buttonClean();
			this.userCountry = apsPermissionService.getUserCountry();
	}

	ngOnInit() {
		this.apsPermissionService.permissionValidation(ApsPermitedScreen.screenContractSearch);

		if(this.contractService.getFilterContractSearch() != null) {			
			this.filterContractSearch = this.contractService.getFilterContractSearch();			
		} 
		
		this.translateService.onLangChange.subscribe((langChange:any)=>{
			switch(langChange.lang){
			case "es-CL" || "es-CO":
				this.calendarLang = this.es;
				break;
			case "pt-BR":
				this.calendarLang = this.pt;
				break;
		}})
		

		this.getDealerAutomatic();
	}

	filterValidation() {
		if(this.filterContractSearch.document === '' && this.filterContractSearch.vin === ''
			&& this.filterContractSearch.dealer === '' && this.filterContractSearch.contractOriginalNumber == null
			&& this.filterContractSearch.contractDateStart == null && this.filterContractSearch.contractDateEnd == null
			&& this.filterContractSearch.validityDateStart == null && this.filterContractSearch.validityDateEnd == null
			&& this.filterContractSearch.contractStatus == 0) {

				this.messageService.add({
					severity:'error', summary: this.translateService.instant('TRANSLATE.ERROR'), 
					detail: this.translateService.instant('TRANSLATE.FILTER.NOT.SELECTED')
				});
		} else {
			this.buttonFilterSearch();
		}
	}

	buttonFilterSearch() {
		this.filterContractSearch.countries = []; 
		this.filterContractSearch.countries.push(this.userCountry);
		this.productActive = this.addProductActiveDefault();
		this.salesTypeFord = this.addSalesTypeFordDefault();
		

		this.isSubmitted = true;
		this.blockedUi = true;
		this.filterContractSearch.salesTypeFordId = this.salesTypeFord.id;
		this.filterContractSearch.productId = this.productActive.id;
		this.filterContractSearch.operation = OperationCode.CAR;
		this.filterContractSearch.statusType = listStatusType();

		this.contractService.dealerContractSearch(this.filterContractSearch)
		.subscribe((response: FilterContractSearchResultTotalRecord) => {
			this.filterContractSearchResultTotalRecord = response;
			this.isSubmitted = false;
			this.blockedUi = false;
		}, (err) => {

			if (err.status === 401) {

				this.messageService.add({
				 severity: 'error',
				 summary: this.translateService.instant('TRANSLATE.ERROR'),
				 detail: this.translateService.instant('TRANSLATE.USER.WITHOUT.PERMISSION.REFRESH.PAGE')
				});
		 
		 } else {
		 
			 this.messageService.add({
				 severity: 'error',
				 summary: this.translateService.instant('TRANSLATE.ERROR'),
				 detail: this.translateService.instant('TRANSLATE.ERROR.CONTACT.SYSTEM.ADMINISTRATOR')
			 });
		 }

			this.isSubmitted = false;
			this.blockedUi = false;
		});
	}

	loadData(event: LazyLoadEvent) {		
		if(event.first > 0){
			this.filterContractSearch.numberPage = (event.first / event.rows);
			this.filterValidationLazyLoad();
		}else{
			this.filterContractSearch.numberPage = event.first;
			this.filterValidationLazyLoad();
		}		
	}

	filterValidationLazyLoad() {
		if(this.filterContractSearch.document === '' && this.filterContractSearch.vin === ''
		&& this.filterContractSearch.dealer === '' && this.filterContractSearch.contractNumber == null
		&& this.filterContractSearch.contractDateStart == null && this.filterContractSearch.contractDateEnd == null
		&& this.filterContractSearch.validityDateStart == null && this.filterContractSearch.validityDateEnd == null
		&& this.filterContractSearch.contractStatus == 0
		&& !this.filterContractSearch.countries && this.filterContractSearch.globalContract == 0) {

		} else {
			this.buttonFilterSearch();
		}
	}

	buttonClean() {
		this.filterContractSearch = new FilterContractSearch();
		this.filterContractSearch.document = '';
		this.filterContractSearch.vin = '';
		this.filterContractSearch.contractNumber = null;
		this.filterContractSearch.contractDateStart = null;
		this.filterContractSearch.contractDateEnd = null;
		this.filterContractSearch.globalContract = 0;
		this.filterContractSearch.contractStatus = 0;
		this.productActive = this.addProductActiveDefault();
		this.salesTypeFord = this.addSalesTypeFordDefault();
		this.getDealerAutomatic();		
		this.filterContractSearch.countries = null;
	}

	exportExcel() {
		if(this.filterContractSearch.document === '' && this.filterContractSearch.vin === ''
		&& this.filterContractSearch.dealer === '' && this.filterContractSearch.contractOriginalNumber == null
		&& this.filterContractSearch.contractDateStart == null && this.filterContractSearch.contractDateEnd == null
		&& this.filterContractSearch.validityDateStart == null && this.filterContractSearch.validityDateEnd == null
		&& this.filterContractSearch.contractStatus == 0) {

				this.messageService.add({
					severity:'error', summary: this.translateService.instant('TRANSLATE.ERROR'), 
					detail: this.translateService.instant('TRANSLATE.FILTER.NOT.SELECTED')
				});
		
		} else {
			this.isSubmittedExcel = true;
			this.blockedUi = true;

			this.contractService.dealerContractSearchExcel(this.filterContractSearch)
			.subscribe((response: Array<FilterContractSearchResult>) => {
				
				this.importXlsxFromArray(response);

				this.isSubmittedExcel = false;
				this.blockedUi = false;
			}, (err) => {
					if(err.status == 500) {
						this.messageService.add({
							severity:'error', summary: this.translateService.instant('TRANSLATE.ERROR'), 
							detail: this.translateService.instant('TRANSLATE.EXCEL.CANNOT.BE.EXPORTED')
						});
					}

					this.isSubmittedExcel = false;
					this.blockedUi = false;
			});
		}		
    }

	private importXlsxFromArray(array: Array<FilterContractSearchResult>): void {
		import("xlsx").then(xlsx => {
			const worksheet = xlsx.utils.json_to_sheet(array);
			const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data']};
			const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array'});
			this.saveAsExcelFile(excelBuffer, "contracts");
		});
	}

    private saveAsExcelFile(buffer: any, fileName: string): void {
        let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE
        });
		saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    }

	buttonEdit(filterContractSearchResultSimplified: FilterContractSearchResultSimplified) {		
		this.contractService.setFilterContractSearch(this.filterContractSearch);
		this.contractService.setFilterContractSearchResultSimplified(filterContractSearchResultSimplified);
		this.router.navigate(['contract-edit-dealer']);
	}

	statusName(statusId: number): string {		
		return this.statusFilter.find(status => status.value == statusId).label;
	}

	private searchSalesTypeFord() {
		this.contractService.searchSalesTypeFord()
		.subscribe((response: Array<SalesTypeFord>) => {
			this.salesTypeFords.push(this.addSalesTypeFordDefault());
			this.salesTypeFords = this.salesTypeFords.concat(response);
			
			if(this.filterContractSearch.salesTypeFordId > 0){
				this.salesTypeFord = this.salesTypeFords.find(salesTypeFord => salesTypeFord.id == this.filterContractSearch.salesTypeFordId);
			} else {
				this.salesTypeFord = this.addSalesTypeFordDefault();
			}
		}
		, (err) => {
			if(err.status == 500) {
				this.messageService.add({
					severity:'error', summary: this.translateService.instant('TRANSLATE.ERROR'), 
					detail: this.translateService.instant('TRANSLATE.SALE.TYPE.NOT.FOUND')
				});
			}
		});
	}

	private addSalesTypeFordDefault(): SalesTypeFord {
		let salesTypeFord: SalesTypeFord = new SalesTypeFord();
		return salesTypeFord;
	}

	private addProductActiveDefault(): ProductActive {
		let productActive: ProductActive = new ProductActive();
		productActive.description = 'Selecione';
		productActive.id = 0;
		productActive.name = 'Selecione';
		productActive.code = 'Selecione';

		return productActive;
	}


	rangeDateLabel() :string {
		let date: Date = new Date();
		let dateLabelYear: number  = date.getFullYear() + 50; 
		return '1970:' + dateLabelYear;
	}

	getDealerAutomatic() {
		this.cdsid = sessionStorage.getItem('userId');	
		this.dealerService.getDealerNameAndCode(this.cdsid)
		.subscribe((response: DealerApsFordDetailAttributes) => {
			if (response == null) {
				this.filterContractSearch.dealer = '00100';
				this.nameDealer = 'FORD MOTOR COMPANY BRASIL LTDA';
			} else {
				this.filterContractSearch.dealer = response.attributeValue;
				this.nameDealer = response.attributeValue;
			}

			this.buttonFilterSearch();
		});
	}

	// MODAL PDF
	showModalPdf(country: string, contractNumber: number) {

		this.contractId = contractNumber;

		if (country === ConstantsFordProtect.BRA) {

			this.displayModalPdf = true;
		} else {

			// usar a variável abaixo na service
			this.contractId;

			// Criar o Serviço de download do termo e usar as mensagens abaixo
			this.messageService.add({
				severity: 'success',
				summary: this.translateService.instant('TRANSLATE.SUCCESS'),
				detail: this.translateService.instant('TRANSLATE.DOWNLOAD.TERM.SUCCESSFULLY')
			});

			// Em caso de Erro (400, 404 ou 500) no endpoint a seguinte mensagem de Erro deverá ser exibida
			// this.messageService.add({
			// 	severity: 'error',
			// 	summary: this.translateService.instant('TRANSLATE.ERROR'),
			// 	detail: this.translateService.instant('TRANSLATE.DOWNLOAD.COULD.NOT.BE.COMPLETED')
			// });
		}

	}

	opDocument(event: any) {
		this.documentType = event.value;
	}

	downloadPdf() {

		if (this.documentType === undefined || this.documentType === null) {

			this.documentType = null;
		} else {

			if (this.documentType === this.translateService.instant('TRANSLATE.TERM')) {

				// usar a variável abaixo na service
				this.contractId;

				// Criar o Serviço de download do termo e usar os dois itens abaixo
				this.clearModalPdf();

				this.messageService.add({
					severity: 'success',
					summary: this.translateService.instant('TRANSLATE.SUCCESS'),
					detail: this.translateService.instant('TRANSLATE.DOWNLOAD.TERM.SUCCESSFULLY')
				});

				// Em caso de Erro (400, 404 ou 500) no endpoint a seguinte mensagem de Erro deverá ser exibida
				// this.messageService.add({
				// 	severity: 'error',
				// 	summary: this.translateService.instant('TRANSLATE.ERROR'),
				// 	detail: this.translateService.instant('TRANSLATE.DOWNLOAD.COULD.NOT.BE.COMPLETED')
				// });

			} else if (this.documentType === this.translateService.instant('TRANSLATE.TICKET')) {

				this.contractId;

				// Criar o Serviço de download do termo e usar os dois itens abaixo
				this.clearModalPdf();
				this.messageService.add({
					severity: 'success',
					summary: this.translateService.instant('TRANSLATE.SUCCESS'),
					detail: this.translateService.instant('TRANSLATE.DOWNLOAD.TICKET.SUCCESSFULLY')
				});

				// Em caso de Erro (400, 404 ou 500) no endpoint a seguinte mensagem de Erro deverá ser exibida
				// this.messageService.add({
				// 	severity: 'error',
				// 	summary: this.translateService.instant('TRANSLATE.ERROR'),
				// 	detail: this.translateService.instant('TRANSLATE.DOWNLOAD.COULD.NOT.BE.COMPLETED')
				// });

			} else {
				this.documentType = null;
			}
		}

	}

	clearModalPdf() {
		this.displayModalPdf = false;
		this.documentType = '';
	}

}
