import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng';
import { ConstantsFordProtect } from 'src/app/models/constants/constants-ford-protect';
import { PriceEdit } from 'src/app/models/product/price-edit';
import { ApsPermissionService } from 'src/app/services/aps-permission/aps-permission.service';
import { PriceService } from 'src/app/services/price/price.service';

@Component({
  selector: 'price-details-edit',
  templateUrl: './price-details-edit.component.html',
  styleUrls: ['./price-details-edit.component.scss']
})
export class PriceDetailsEditComponent implements OnInit {

  listNamePrice = [
    { label: 'TRANSLATE.SELECT' },
    { label: 'TRANSLATE.SELECT.PRICE.CUSTOMER', value: ConstantsFordProtect.PRICE_CUSTOMER },
    { label: 'TRANSLATE.SELECT.SELLER.PRICE', value: ConstantsFordProtect.SELLER_PRICE },
    { label: 'TRANSLATE.SELECT.COST.FORD', value: ConstantsFordProtect.COST_FORD }
  ];

  listCurrency = [
    { label: 'TRANSLATE.SELECT' },
    { label: 'TRANSLATE.SELECT.COUNTRY.CURRENCY.BRA', value: ConstantsFordProtect.REAL },
    { label: 'TRANSLATE.SELECT.COUNTRY.CURRENCY.OTHERS', value: ConstantsFordProtect.PESO }
  ];

  selectedNamePrice: string;
  selectedCurrency: string;
  inputPrice: string;
  inputDescription: string;
  selectDisabled: boolean;
  descriptionPrice: string = "Ola descrição Teste";
  id: number;
  price: PriceEdit = {} as PriceEdit;
  editedPrice: PriceEdit = {} as PriceEdit;
  blockedUi: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private priceService: PriceService,
    private apsPermissionService: ApsPermissionService,
    private translateService: TranslateService,
    private messageService: MessageService,
  ) { }

  ngOnInit() {

    this.route.paramMap.subscribe(params => {
      this.id = +params.get('id');
    });
    this.buttonCancel();
  }

  buttonReturn() {
    this.router.navigate(['view-plans'], { skipLocationChange: false, replaceUrl: false });
  }

  namePriceChange(event: any) {
    this.selectedNamePrice = event.value;
  }

  currencyChange(event: any) {
    this.selectedCurrency = event.value;
  }

  formatNumber(event: any) {
      const input = event.target as HTMLInputElement;
      let priceTreatment = input.value;
      priceTreatment = priceTreatment.replace(/[^\d,.]/g, '');

      let parts = priceTreatment.split(',');
      const hasComma = parts.length > 1;

      if (parts[0].length > 5 && !hasComma) {
        parts[0] = parts[0].slice(0, 5).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ',' + parts[0].slice(5);
      }
      if (parts.length > 1 && parts[1].length > 2) {
        parts[1] = parts[1].slice(0, 2);
      }

      input.value = parts.join(',');
      const priceComma = input.value;
      let valueWithoutPoint = priceComma.replace(/\./g, '');
      valueWithoutPoint = valueWithoutPoint.replace(',', '.')
      this.inputPrice = valueWithoutPoint;
      if (Number(this.inputPrice) <= 0){
        this.inputPrice = null;
      }
  }

  buttonEdit() {

    (<HTMLInputElement>document.getElementById("valuePrice")).disabled = false;
    (<HTMLInputElement>document.getElementById("priceDescription")).disabled = false;
    this.selectDisabled = false;

    (<HTMLInputElement>document.getElementById("titleDetails")).style.display = 'none';
    (<HTMLInputElement>document.getElementById("titleEdit")).style.display = 'block';
    (<HTMLInputElement>document.getElementById("subTitle")).style.display = 'block';
    (<HTMLInputElement>document.getElementById("btnReturn")).style.display = 'none';
    (<HTMLInputElement>document.getElementById("btnEdit")).style.display = 'none';
    (<HTMLInputElement>document.getElementById("btnCancel")).style.display = 'block';
    (<HTMLInputElement>document.getElementById("btnSave")).style.display = 'block';

  }

  buttonCancel() {

    (<HTMLInputElement>document.getElementById("valuePrice")).disabled = true;
    (<HTMLInputElement>document.getElementById("priceDescription")).disabled = true;
    (<HTMLInputElement>document.getElementById("currency")).style.color = '#202020';
    (<HTMLInputElement>document.getElementById("currency")).style.backgroundColor = '#E5E5E5';
    (<HTMLInputElement>document.getElementById("namePrice")).style.color = '#202020';
    (<HTMLInputElement>document.getElementById("namePrice")).style.backgroundColor = '#E5E5E5';
    this.selectDisabled = true;
    (<HTMLInputElement>document.getElementById("titleDetails")).style.display = 'block';
    (<HTMLInputElement>document.getElementById("titleEdit")).style.display = 'none';
    (<HTMLInputElement>document.getElementById("subTitle")).style.display = 'none';
    (<HTMLInputElement>document.getElementById("btnReturn")).style.display = 'block';
    (<HTMLInputElement>document.getElementById("btnEdit")).style.display = 'block';
    (<HTMLInputElement>document.getElementById("btnCancel")).style.display = 'none';
    (<HTMLInputElement>document.getElementById("btnSave")).style.display = 'none';

    this.restoringValues();
  }


  buttonSave() {

    this.blockedUi = true;

    this.inputDescription = (<HTMLInputElement>document.getElementById("priceDescription")).value;
    this.inputPrice = (<HTMLInputElement>document.getElementById("valuePrice")).value;

    if (this.selectedNamePrice === "") {

      this.selectedNamePrice = null;
      this.blockedUi = false;
    }
    if (this.selectedCurrency === "") {

      this.selectedCurrency = null;
      this.blockedUi = false;
    }
    if (this.inputPrice == null || this.inputPrice === "" || this.inputPrice.length <= 0 || Number(this.inputPrice) < 1 || this.inputPrice.length > 9) {

      this.inputPrice = null;
      this.blockedUi = false;
    }

    if (this.inputDescription == "" || this.inputDescription.length < 4 || this.inputDescription.length > 100) {

      this.inputDescription = null;
      this.blockedUi = false;

    }
    else {
      this.editedPrice.itemPrice = parseFloat(this.inputPrice);
      this.editedPrice.itemName = this.selectedNamePrice;
      this.editedPrice.itemPriceUnit = this.selectedCurrency;
      this.editedPrice.itemDescription = this.inputDescription;
      this.editedPrice.createUser = this.apsPermissionService.getUserCsid();
      this.updatePrice();


    }

  }

  restoringValues() {

    this.selectedNamePrice = '';
    this.selectedCurrency = '';
    this.inputPrice = '';
    this.searchById();
  }

  searchById() {

    this.blockedUi = true;
    this.priceService.getByPriceId(this.id).subscribe((response) => {
      this.price.priceId = response.id;
      this.price.itemName = response.itemName;
      this.price.itemDescription = response.itemDescription;
      this.price.itemPrice = response.itemPrice;
      this.price.itemPriceUnit = response.itemPriceUnit;
      this.selectedCurrency = response.itemPriceUnit;
      this.selectedNamePrice = response.itemName;
      this.blockedUi = false;
    }, (err) => {
      this.blockedUi = false;
      this.messageService.add({
        severity: 'error',
        summary: this.translateService.instant('TRANSLATE.ERROR'),
        detail: this.translateService.instant('TRANSLATE.PRICE.NOT.FOUND')
      });
    })

  }

  updatePrice() {
    this.priceService.updatePrice(this.editedPrice, this.price.priceId).subscribe(response => {
      this.messageService.add({
        severity: 'success',
        summary: this.translateService.instant('TRANSLATE.SUCCESS'),
        detail: this.translateService.instant('TRANSLATE.SUCCESS.EDIT')
      });
      this.blockedUi = false;
      this.buttonCancel();
      this.searchById();
    }, (err) => {
      this.blockedUi = false;
      this.messageService.add({
        severity: 'error',
        summary: this.translateService.instant('TRANSLATE.ERROR'),
        detail: this.translateService.instant('TRANSLATE.ERROR.SAVE.CHANGES')
      });
    })
  }

}
