import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MessageService } from "primeng/api";
import { Observable } from "rxjs";
import { map } from 'rxjs/operators';
import { NewProduct } from "src/app/models/product/new-product";
import { Product } from "src/app/models/product/product";
import { ProductActive } from "src/app/models/product/product-active";
import { ProductEdit } from "src/app/models/product/product-edit";
import { ProductVisit } from "src/app/models/product/product-visit";
import { environment } from "src/environments/environment";

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  private apiUrl = environment.apiUrl;
  private country: string = 'BRA';
  private operation: string = 'CAR';

  constructor(private httpClient: HttpClient, private messageService: MessageService) {
  }

  getProducts(): Observable<Array<Product>> {
    return this.httpClient.get<Array<Product>>(`${this.apiUrl}/api/products/${this.country}/${this.operation}`)
      .pipe(
        map((response: Array<Product>) => {
          return response;
        })
      );
  }

  getActivesProductsByCountry(country: string, operation: string, statusProduct: number): Observable<Array<ProductActive>> {
    return this.httpClient.get<Array<ProductActive>>(`${this.apiUrl}/api/products/active/${country}/${operation}/${statusProduct}`)
      .pipe(
        map((response: Array<ProductActive>) => {
          return response;
        })
      );
  }

  getActivesProductsAndNotRestrictedByCountry(country: string): Observable<Array<ProductActive>> {
    return this.httpClient.get<Array<ProductActive>>(`${this.apiUrl}/api/products/active/restricted/${country}`)
      .pipe(
        map((response: Array<ProductActive>) => {
          return response;
        })
      );
  }

  getProductActiveCodeCountries(countries: Array<string>): Observable<Array<ProductActive>> {
    return this.httpClient.post(`${this.apiUrl}/api/products/active/code/countries`, countries, httpOptions)
      .pipe(
        map((response: Array<ProductActive>) => {
          return response;
        })
      );
  }

  getProductsByCountry(countries: Array<string>): Observable<Array<ProductVisit>> {
    return this.httpClient.post<Array<ProductVisit>>(`${this.apiUrl}/api/products/countries`, countries, httpOptions)
      .pipe(
        map((response: Array<ProductVisit>) => {
          return response;
        })
      );
  }

  getProductsByCountryForProductPolicies(country: string): Observable<Array<ProductVisit>> {
    return this.httpClient.get<Array<ProductVisit>>(`${this.apiUrl}/api/products/${country}`)
    .pipe(
      map((response: Array<ProductVisit>) => {
        return response;
      })
    );
}

  getAllProducts(): Observable<Array<ProductVisit>> {
    return this.httpClient.get(`${this.apiUrl}/api/products`)
      .pipe(
        map((response: Array<ProductVisit>) => {
          return response;
        })
      );
  }

  getProductActiveCodeCountry(country: string): Observable<Array<ProductActive>> {
    return this.httpClient.get<Array<ProductActive>>(`${this.apiUrl}/api/products/active/code/${country}`)
      .pipe(
        map((response: Array<ProductActive>) => {
          return response;
        })
      );
  }

  createProduct(cadProduct: NewProduct): Observable<NewProduct> {
    return this.httpClient.post<NewProduct>(`${this.apiUrl}/api/products/create/product`, cadProduct);
  }

  updateProduct(editedProduct: ProductEdit): Observable<Product> {
    return this.httpClient.put<ProductEdit>(`${this.apiUrl}/api/products/product`, editedProduct);
  }

  getProductById(id: number): Observable<any> {
    return this.httpClient.get<any>(`${this.apiUrl}/api/products/id/${id}`)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  

}
