export class FilterContractSearchResultSimplified {

	contractNumber: string;
	contractOriginalNumber: string;
	vin: string;
	status: number;
	planName: string;
	dateStart: Date;
	coverageEndDate: Date;
	limite: string;
	contractKey: number;
	country: string;
	productKey: number;
	coverageCategoryKey: number;
	productCode: string;  	

	constructor() {
	}
}
